
import React from 'react';
import useMenu from 'hooks/useMenu';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';

const Nav = styled.div`
    margin-top: 20px;

    span:first-child {
        color: #005da6;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0;
    }
    span:last-child {
        color: #000000;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0;
    }
`;

const AngleIcon = styled(FontAwesomeIcon)`
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    font-size: 16px;
    margin: 0px 8px;
    position: relative;
    top: -1px;
`;

const BreadCrumbs = ({title: titleProp, ...rest}) => {
    const {showMenu, title} = useMenu();

    return (!showMenu && <Nav {...rest}>
        <span className="ps-hidden-xs ps-hidden-sm ps-hidden-md">
            My Account
            <AngleIcon icon={faAngleRight} />
        </span>
        <span>{titleProp || title}</span>
    </Nav>);
};

export default BreadCrumbs;
